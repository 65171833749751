import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { useKey } from 'react-use';

import { SimpleInput } from 'components/FormHelpers';
import { PrimaryButton, DefaultButton } from 'ui/Button';
import { tokenSchema } from 'utils/validations';

function generateToken(length) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

function AddTokenModal({ onClose, onSave, tokensList = [], shouldResetForm = true, isCreating = false }) {
  const formik = useFormik({
    initialValues: {
      name: '',
      value: ''
    },
    validationSchema: () => tokenSchema(),
    onSubmit: async (data) => {
      if (tokensList.map((token) => token.trim().toLowerCase()).includes(data.name.trim().toLowerCase())) {
        toast.error('Token with same name already exists');
        return;
      }
      onSave({ name: data.name, value: data.value });
      if (shouldResetForm) {
        formik.resetForm();
      }
    }
  });

  const predicate = (event) => event.key === 'Enter';
  useKey(predicate, () => handleSubmit());

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting } = formik;

  const handleCopy = () => {
    navigator.clipboard.writeText(values.value);
    toast.success('Token copied to clipboard!');
  };

  useEffect(() => {
    setFieldValue('value', generateToken(32));
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-[9999]">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 md:w-[764px]">
        <h2 className="text-xl font-bold mb-4">Add Token</h2>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <SimpleInput
              field={{
                name: 'name',
                label: 'Name',
                type: 'text',
                placeholder: '',
                required: true
              }}
              autoFocus
              value={values.name}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              className="mb-2"
            />
            <div className="mb-4">
              <div className="flex items-center">
                <div className="flex-grow">
                  <SimpleInput
                    field={{
                      name: 'value',
                      label: 'Token *',
                      type: 'text',
                      placeholder: '',
                      required: false
                    }}
                    autoFocus
                    value={values.value}
                    handleChange={() => {}}
                    errors={errors}
                    touched={touched}
                    readOnly
                    viewOnly
                    disabled
                  />
                </div>
                <button
                  type="button"
                  onClick={handleCopy}
                  className="ml-2 mt-3 w-14 h-14 p-2 bg-gray-200 rounded-md hover:bg-gray-300"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </div>
            </div>
            <div className="flex justify-end">
              <PrimaryButton
                type="button"
                onClick={handleSubmit}
                text="OK"
                className="mr-4"
                disabled={isSubmitting || isCreating}
              />
              <DefaultButton type="button" onClick={onClose} text="Cancel" />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
}

AddTokenModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  tokensList: PropTypes.array,
  shouldResetForm: PropTypes.bool,
  isCreating: PropTypes.bool
};

export default AddTokenModal;
