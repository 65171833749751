import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useRecoilState } from 'recoil';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Layout
import Layout from 'layout';
// Components
import Seo from 'components/Seo';
import LoadingPage from 'ui/LoadingPage';
// Profile Components
import { ProfileDetails, ProfilePassword, Metadata, Statistics, Tokens } from 'components/Profile';
// Import from State
import { isLoggedIn as loginState, profileState, configurationState } from 'state';
// Auth Function
import { checkLoginStatus } from 'func/auth';

function ProfilePage() {
  const [profile, setProfile] = useRecoilState(profileState);
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(loginState);
  const [, setConfiguration] = useRecoilState(configurationState);
  const [tabIndex, setTabIndex] = useState(0);

  const initialize = async () => {
    const result = await checkLoginStatus();
    if (!result) return navigate('/login');
    const { profile, isLoggedIn, configuration } = result;
    setProfile(profile);
    setConfiguration(configuration);
    return setTimeout(() => setIsLoggedIn(isLoggedIn), 1000);
  };

  useEffect(() => {
    // Check the Valid Token
    if (profile?.role?.value === 'Guest') {
      navigate('/guest/booking/');
      return;
    }
    if (isLoggedIn) return;
    initialize();

    // Get tab from URL hash
    const hash = window.location.hash.replace('#', '');
    const tabs = ['details', 'password', 'metadata', 'statistics', 'tokens'];
    const index = tabs.indexOf(hash);
    if (index !== -1) {
      setTabIndex(index);
    }
  }, []);

  const handleTabSelect = (index) => {
    const tabs = ['details', 'password', 'metadata', 'statistics', 'tokens'];
    setTabIndex(index);
    window.location.hash = tabs[index];
  };

  return (
    <>
      <Seo title="Profile - Veezu" />
      <LoadingPage hide={isLoggedIn} />
      {profile && (
        <Layout showLogoutButton>
          <div className="w-full min-h-[calc(100vh-59px)] md:min-h-max">
            <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
              <TabList className="flex items-center bg-primary-black pl-4 pr-4 font-poppins md:pl-8 md:pr-8 xl:pr-16">
                <Tab
                  className="mr-10 cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100 md:mr-14"
                  selectedClassName="bg-transparent text-opacity-100 border-white"
                >
                  Details
                </Tab>
                <Tab
                  className="mr-10 cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100 md:mr-14"
                  selectedClassName="bg-transparent text-opacity-100 border-white"
                >
                  Password
                </Tab>
                <Tab
                  className="mr-10 cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100 md:mr-14"
                  selectedClassName="bg-transparent text-opacity-100 border-white"
                >
                  Metadata
                </Tab>
                <Tab
                  className="cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100 md:mr-14"
                  selectedClassName="bg-transparent text-opacity-100 border-white"
                >
                  Statistics
                </Tab>
                {profile?.isAffiliate && (
                  <Tab
                    className="cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100 md:mr-14"
                    selectedClassName="bg-transparent text-opacity-100 border-white"
                  >
                    Tokens
                  </Tab>
                )}
              </TabList>
              <TabPanel className="mt-4 pl-4 pr-4 md:mt-8 md:pl-8 md:pr-8 xl:pr-16">
                <ProfileDetails />
              </TabPanel>
              <TabPanel className="pl-4 pr-4 md:pl-8 md:pr-8 xl:pr-16">
                <ProfilePassword />
              </TabPanel>
              <TabPanel className="pl-4 pr-4 md:pl-8 md:pr-8 xl:pr-16">
                <Metadata />
              </TabPanel>
              <TabPanel className="pl-4 pr-4 md:pl-8 md:pr-8 xl:pr-16">
                <Statistics />
              </TabPanel>
              {profile?.isAffiliate && (
                <TabPanel className="pl-4 pr-4 md:pl-8 md:pr-8 xl:pr-16">
                  <Tokens />
                </TabPanel>
              )}
            </Tabs>
          </div>
        </Layout>
      )}
    </>
  );
}

export default ProfilePage;
