import { PrimaryButton } from 'components/UI/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Assets
import { ReduceSidebarIcon, ExpandedSidebarIcon, DropdownIcon } from 'svgs';

AdminHamburger.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  overFlow: PropTypes.bool
};

function AdminHamburger({ children, className, buttonLabel = '', overFlow = false }) {
  const [smallMenu, setSmallMenu] = useState(false);

  const smallMenuHandler = () => setSmallMenu(!smallMenu);

  let ReactComponent = DefiButton;

  if (buttonLabel) {
    ReactComponent = PrimaryButton;
  }

  // Render Hamburger Menu with buttons if small screen
  if (overFlow)
    return (
      <div className="relative">
        <ReactComponent
          type="button"
          onClick={smallMenuHandler}
          text={buttonLabel}
          className={
            buttonLabel ? 'flex items-center justify-center border rounded border-gray-02 px-4 py-1 button' : ''
          }
        >
          {buttonLabel && (
            <span
              className={`block ml-5 transform transition-all duration-200 ${smallMenu ? 'rotate-180' : 'rotate-0'}`}
            >
              <DropdownIcon className="stroke-white" />
            </span>
          )}
          {!buttonLabel && <div>{!smallMenu ? <ExpandedSidebarIcon /> : <ReduceSidebarIcon />}</div>}
        </ReactComponent>
        {smallMenu && (
          <div
            className={`grid gap-2 absolute right-0 bg-white shadow-md z-10 pl-5 pr-3 py-6 border rounded border-gray-02 ${
              buttonLabel ? 'top-14' : 'top-10'
            }`}
          >
            {children}
          </div>
        )}
      </div>
    );
  return <div className={`${className} ${overFlow ? 'hidden' : ''}`}>{children}</div>;
}

export default AdminHamburger;

// eslint-disable-next-line react/prop-types
function DefiButton({ children, onClick, buttonLabel }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonLabel ? 'flex items-center justify-center border rounded border-gray-02 px-4 py-1 button' : ''}
    >
      {children}
    </button>
  );
}
