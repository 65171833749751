import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';

// Components
import Seo from 'components/Seo';
import Table from 'components/Table';
import { PrimaryButton } from 'ui/Button';
import { getTokens, createToken, removeToken } from 'ajax/profile';
import ReactModal from 'ui/ReactModal';
import { ReactConfirmAlert } from 'ui/ReactConfirmAlert';
import AdminHamburger from 'components/AdminHamburger';
import AddTokenModal from 'components/Admin/Affiliates/AddToken';

// Hooks
import { useContainerDimensions } from 'hooks';

function Tokens() {
  const tableRef = useRef(null);
  const { width: tableWidth } = useContainerDimensions(tableRef);
  const [localTokens, setLocalTokens] = useState([]);
  const [confirmModal, setConfirmModal] = useState({ status: false, tokenName: null });
  const [isDeleting, setIsDeleting] = useState(false);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { isLoading, error, data, refetch } = useQuery(
    {
      queryKey: ['tokens'],
      queryFn: async () => {
        const response = await getTokens();
        if (response.status !== 200) return { rows: [], columns: [] };
        // Get tokens from objects and save as string
        const tokens = response.data.map((token) => token.name);
        setLocalTokens(tokens);
        const columns = createColumns();
        const rows = createRows(response.data);
        return { rows, columns };
      }
    },
    { refetchOnWindowFocus: false, enabled: true }
  );

  // Create Columns Schema from AJAX
  function createColumns() {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        disableFilters: true,
        disableSortBy: true
      },
      {
        Header: '',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        width: 48, // Approve / UnApprove Button is of Size 85px in components/Invoices/Approval
        minWidth: 48,
        maxWidth: 48
      }
    ];
  }

  // Create Rows Schema from AJAX
  function createRows(data) {
    const rowsData = [];
    data.forEach((token) => {
      rowsData.push({
        name: token.name,
        action: (
          <div className="">
            <PrimaryButton
              text="cancel"
              onClick={() => setConfirmModal({ status: true, tokenName: token.name })}
              hideLabel
              className="!w-16"
            />
          </div>
        )
      });
    });
    return rowsData;
  }

  async function handleSubmit(value) {
    try {
      // Check if token name already exists (case insensitive, no whitespace)
      const normalizedNewName = value.name.toLowerCase().replace(/\s/g, '');
      const nameExists = localTokens.some((token) => token.toLowerCase().replace(/\s/g, '') === normalizedNewName);

      if (nameExists) {
        toast.error('A token with this name already exists');
        return;
      }

      setIsCreating(true);
      const response = await createToken(value);
      if (response.status === 200) {
        setLocalTokens((prev) => [...prev, value.name]);
        toast.success('Token created successfully');
        setIsOpen(false);
        refetch();
        return;
      }
      if (response.data.type === 'Validation') {
        response.data.data.map((error) => toast.error(error.message));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while creating the token');
    } finally {
      setIsCreating(false);
    }
  }

  const handleDeleteToken = async (tokenName) => {
    try {
      setIsDeleting(true);
      const response = await removeToken({ name: tokenName });
      if (response.status === 200) {
        setLocalTokens((prev) => prev.filter((token) => token.name !== tokenName));
        toast.success('Token deleted successfully');
        setConfirmModal({ status: false, tokenName: null });
        refetch();
        return;
      }
      if (response.data.type === 'Validation') {
        response.data.data.map((error) => toast.error(error.message));
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('An error occurred while deleting the token');
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Seo title="Tokens - Veezu" />
      <div className="mb:10 md:mb-20 flex-1" ref={tableRef}>
        {isLoading ? (
          <Skeleton count={4} className="mb-1 h-8" containerClassName="block mb-8" />
        ) : (
          data && (
            <Table data={data.rows} columns={data.columns} showSort setCount={setCount}>
              <div className="mb-2 flex items-center justify-between md:mb-6">
                <h2 className="h3-mobile md:h4-desktop mr-2">
                  Tokens{' '}
                  {count > 0 && (
                    <span>
                      <small>({count})</small>
                    </span>
                  )}
                </h2>
                <AdminHamburger className="text-right mb-4 md:mb-0" overFlow={tableWidth <= 750}>
                  {/* <DefaultButton
                    type="button"
                    onClick={refetch}
                    text="Refresh"
                    className="mr-2"
                    isLoading={isRefetching}
                  />
                  <DefaultButton
                    type="button"
                    onClick={downloadListHandler}
                    text="Export"
                    className="mr-2"
                    isLoading={isDownloading}
                  /> */}
                  <PrimaryButton text="Create" onClick={() => setIsOpen(true)} />
                </AdminHamburger>
              </div>
            </Table>
          )
        )}
        {error && (
          <h2>Sorry, an error has occurred, please try again or contact the administrator if the problem persists.</h2>
        )}
      </div>
      {isOpen && (
        <AddTokenModal
          onSave={(token) => handleSubmit(token)}
          onClose={() => setIsOpen(false)}
          tokensList={localTokens}
          shouldResetForm={false}
          isCreating={isCreating}
        />
      )}
      {confirmModal.status && (
        <ReactModal>
          <ReactConfirmAlert
            message="Are you sure you want to delete this token?"
            buttonText="Yes"
            onConfirm={() => handleDeleteToken(confirmModal.tokenName)}
            onClose={() => setConfirmModal({ status: false, tokenName: null })}
            disabled={isDeleting}
          />
        </ReactModal>
      )}
    </>
    // <div className="container mx-auto p-4">
    //   <div className="mb-8">
    //     <h2 className="text-xl font-bold mb-4">Your Tokens</h2>
    //     {localTokens.length === 0 ? (
    //       <p className="text-gray-500">No tokens found</p>
    //     ) : (
    //       <div className="space-y-2">
    //         {localTokens.map((token) => (
    //           <div key={token.id} className="flex items-center justify-between bg-gray-100 p-3 rounded">
    //             <div>
    //               <p className="font-semibold">{token.name}</p>
    //             </div>
    //             <button
    //               type="button"
    //               onClick={() => setConfirmModal({ status: true, tokenName: token.name })}
    //               className="text-red-500 hover:text-red-700"
    //             >
    //               <FontAwesomeIcon icon={faTimes} />
    //             </button>
    //           </div>
    //         ))}
    //       </div>
    //     )}
    //   </div>

    //   <div className="bg-white p-6 rounded-lg shadow">
    //     <h3 className="text-lg font-bold mb-4">Create New Token</h3>
    //     <FormikProvider value={formik}>
    //       <Form onSubmit={handleSubmit}>
    //         <div className="space-y-4">
    //           <SimpleInput
    //             field={{
    //               name: 'name',
    //               label: 'Name',
    //               type: 'text',
    //               placeholder: '',
    //               required: true
    //             }}
    //             value={values.name}
    //             handleChange={handleChange}
    //             errors={errors}
    //             touched={touched}
    //           />
    //           <SimpleInput
    //             field={{
    //               name: 'value',
    //               label: 'Value',
    //               type: 'text',
    //               placeholder: '',
    //               required: true,
    //               readOnly: true
    //             }}
    //             value={values.value}
    //             handleChange={handleChange}
    //             errors={errors}
    //             touched={touched}
    //             readOnly
    //             viewOnly
    //             disabled
    //           />
    //           <PrimaryButton type="submit" text="Create Token" disabled={formik.isSubmitting} />
    //         </div>
    //       </Form>
    //     </FormikProvider>
    //   </div>

    //
    // </div>
  );
}

export default Tokens;
